import Vue from 'vue';
import AdvancedSearchHomeV2 from './components/advanced_search_home_v2.vue';

import 'select2/dist/css/select2.css';
import 'daterangepicker/daterangepicker.css';

export default new Vue({
    el: '#paytour_advanced_search_bar',
    components: { AdvancedSearchHomeV2 }
});
