<template>
<div
  id="paytour_advanced_search_bar_container"
  :style="{
    'z-index': mobileMenuVisible ? 1000 : 10
  }"
  class="container">
  <div class="advanced-search-bar-container">
    <div
      v-if="byText && !insertTextMode && !textInput"
      class="advanced-search-bar-subcontainer-textsearch"
      @click="insertTextMode = true">
      <div style="display: inline-block; float: left;">
        <i
          style="font-size: 30px; font-size: 30px; margin-top: 10px; margin-left: 10px; margin-right: 10px;"
          class="fa-solid fa-person-walking payt-color-primary" />
      </div>
      <div style="display: inline-block;">
        <div style="height: 20px;">
          <span class="advanced-search-bar-title payt-color-primary">Pesquisar {{ labelProduto.toLowerCase() }}</span>
        </div>
        <span class="advanced-search-bar-subtitle">Pesquisar pelo nome</span>
      </div>
    </div>
    <div
      v-if="byText && (insertTextMode || textInput)"
      class="advanced-search-bar-subcontainer-textsearch">
      <i
        style="font-size: 25px; margin-right: 10px;"
        class="fa fa-search payt-color-primary"
        aria-hidden="true" />
      <input
        id="field_nome_passeio"
        v-model="textInput"
        class="advanced-search-bar-textinput"
        name="nome_passeio"
        type="text"
        @blur="insertTextMode = false">
    </div>

    <div
      v-if="byDestino"
      id="advanced_search_detino"
      class="advanced-search-bar-subcontainer"
      :style="{'display': 'flex', 'max-width': (!byText ? 'unset' : false)}">
      <div
        style="width: 80%;"
        @click="openSelectDestino()">
        <i
          style="font-size: 30px; font-size: 30px; margin-top: 8px; margin-left: 10px; margin-right: 10px; float: left;"
          class="fa-solid fa-map-location-dot payt-color-primary" />
        <div style="height: 20px;">
          <span class="advanced-search-bar-title payt-color-primary">Destino</span>
        </div>
        <span class="advanced-search-bar-subtitle">{{ formatedDestino }} <i
          v-if="range == ''"
          class="fa fa-angle-down"
          aria-hidden="true" /></span>
        <select
          id="advanced_search_destino_field"
          style="display: none;">
          <option value="">
            - Selecione -
          </option>
          <option
            v-for="itemDestino in listaDestinos"
            :key="itemDestino['id']"
            :value="itemDestino['id']">
            {{
              (itemDestino['titulo'] ? itemDestino['titulo'] + ' - ' : '')
                + itemDestino['cidade'] + ', '
                + itemDestino['estado'] + ', '
                + itemDestino['pais']
            }}
          </option>
        </select>
      </div>
      <div
        v-if="geolocationAvailable"
        style="width: 20%;text-align: right;padding-top: 10px;padding-right: 10px;"
        @click="openSelectGeoloc()">
        <i
          class="fa-solid fa-location-crosshairs"
          style="font-size: 26px; color: #4A545A;" />
        <select
          id="advanced_search_geolocation_field"
          style="display: none;">
          <option value="">
            - Selecione -
          </option>
          <option value="10">
            até 10km de você
          </option>
          <option value="50">
            até 50km de você
          </option>
          <option value="100">
            até 100km de você
          </option>
          <option value="200">
            até 200km de você
          </option>
        </select>
      </div>
    </div>

    <div
      v-if="byDate"
      id="advanced_search_data"
      class="advanced-search-bar-subcontainer">
      <i
        style="font-size: 30px; font-size: 30px; margin-top: 8px; margin-left: 10px; margin-right: 10px; float: left;"
        class="fa-regular fa-calendar payt-color-primary" />
      <div style="height: 20px;">
        <span class="advanced-search-bar-title payt-color-primary">Qual a melhor data?</span>
      </div>
      <span class="advanced-search-bar-subtitle">{{ formatedData }} <i
        class="fa fa-angle-down"
        aria-hidden="true" /></span>
    </div>

    <div
      v-if="byMesAno"
      id="advanced_search_mesAno"
      class="advanced-search-bar-subcontainer"
      @click="openSelectMesAno()">
      <div style="height: 20px;">
        <span class="advanced-search-bar-title payt-color-primary">Qual o melhor mês?</span>
      </div>
      <span class="advanced-search-bar-subtitle">{{ formatedMesAno }} <i
        class="fa fa-angle-down"
        aria-hidden="true" /></span>
      <select
        id="advanced_search_mesAno_field"
        style="display: none;">
        <option value="">
          - Selecione -
        </option>
        <option
          v-for="curMesAno in meses"
          :key="curMesAno"
          :value="curMesAno">
          {{ formatMesAno(curMesAno) }}
        </option>
      </select>
    </div>

    <div
      v-if="byDateRange"
      id="advanced_search_range_datas"
      class="advanced-search-bar-subcontainer">
      <i
        style="font-size: 30px; font-size: 30px; margin-top: 8px; margin-left: 10px; margin-right: 10px; float: left;"
        class="fa-regular fa-calendar payt-color-primary" />
      <div style="height: 20px;">
        <span class="advanced-search-bar-title payt-color-primary">Qual a melhor data?</span>
      </div>
      <span class="advanced-search-bar-subtitle">{{ formatedDateRange }} <i
        class="fa fa-angle-down"
        aria-hidden="true" /></span>
    </div>

    <button
      class="payt-btn-loja-round"
      style="padding: 12px 15px; margin-left: 10px; min-width: 120px; color: white;"
      type="button"
      @click="submit">
      <i
        style="font-size: 16px; margin-right: 5px; color: white;"
        class="fa fa-search"
        aria-hidden="true" /> Buscar
    </button>
  </div>

  <div
    class="advanced-search-bar-mobile-search-btn"
    @click="showSearchScreen">
    <span
      class="payt-color-primary"
      style="weight: 500;">Buscar por...</span>
    <i
      style="font-size: 20px;"
      class="fa fa-search payt-color-primary"
      aria-hidden="true" />
  </div>

  <div
    id="advancedSearchScreen"
    :class="{
      'search-mobile-container': true,
      'd-none': !mobileMenuVisible,
    }">
    <div class="mb-3">
      <div
        class="payt-icon-container-round me-5"
        :style="{
          'width': '36px',
          'height': '36px',
          'border-color': corPrimaria
        }"
        @click="hideSearchScreen">
        <i
          class="fa fa-angle-down payt-color-primary"
          style="font-size: 14px;" />
      </div>
    </div>

    <div class="mb-3">
      <h2
        class="payt-color-primary"
        style="font-size: 21px;">
        Defina sua preferência de:
      </h2>
    </div>

    <div
      v-if="byText"
      class="form-group">
      <label for="field_nome_passeio_mobile">Pesquisar {{ labelProduto }}</label>
      <input
        id="field_nome_passeio_mobile"
        v-model="textInput"
        type="text"
        class="form-control"
        placeholder="Buscar pelo nome">
    </div>
    <div v-if="byDestino">
      <div class="form-group">
        <label>Destino</label>
        <select
          v-model="destino"
          class="form-control">
          <option value="">
            - Selecione -
          </option>
          <option
            v-for="curDestino in listaDestinos"
            :key="curDestino['id']"
            :value="curDestino['id']">
            {{
              (curDestino['titulo'] ? curDestino['titulo'] + ' - ' : '')
                + curDestino['cidade'] + ', '
                + curDestino['estado'] + ', '
                + curDestino['pais']
            }}
          </option>
        </select>
      </div>
      <div
        v-if="geolocationAvailable"
        class="form-group">
        <label>Perto de você</label>
        <select
          v-model="range"
          class="form-control">
          <option value="">
            - Selecione -
          </option>
          <option value="10">
            até 10km de você
          </option>
          <option value="50">
            até 50km de você
          </option>
          <option value="100">
            até 100km de você
          </option>
          <option value="200">
            até 200km de você
          </option>
        </select>
      </div>
    </div>
    <div
      v-if="byMesAno"
      class="form-group">
      <label>Mês</label>
      <select
        v-model="mesAno"
        class="form-control">
        <option value="">
          - Selecione -
        </option>
        <option
          v-for="curMesAno in meses"
          :key="curMesAno"
          :value="curMesAno">
          {{ formatMesAno(curMesAno) }}
        </option>
      </select>
    </div>
    <div
      v-if="byDate"
      class="form-group">
      <label>Dia</label>
      <div
        id="advanced_search_data_mobile"
        class="advanced-search-mobile-daterange-input">
        <span>{{ formatedData }}</span>
        <i
          class="fa fa-calendar"
          aria-hidden="true" />
      </div>
    </div>
    <div
      v-if="byDateRange"
      class="form-group">
      <label>Intervalo de datas</label>
      <div
        id="advanced_search_range_datas_mobile"
        class="advanced-search-mobile-daterange-input">
        <span>{{ formatedDateRange }}</span>
        <i
          class="fa fa-calendar"
          aria-hidden="true" />
      </div>
    </div>
    <div style="text-align: right;">
      <button
        class="payt-btn-loja-round d-block w-100 mt-5"
        type="button"
        @click="submit">
        Pesquisar
      </button>
      <button
        class="payt-btn-loja-square-outline d-block w-100 mt-3"
        type="button"
        @click="clean">
        Limpar
      </button>
    </div>
  </div>
</div>
</template>

<script>
import $ from 'jquery';
import 'select2';
import 'daterangepicker';
import moment from 'moment';
import Vue from 'vue';

var labelMeses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
];

const createDateRangePicker = function (id, isMobile, onSelected, onClean, byText) {
    $(id).daterangepicker({
        'minDate': moment(),
        'opens': byText ? 'left' : 'right',
        'parentEl': (isMobile ? '#advancedSearchScreen .modal-body' : 'body'),
        'autoApply': isMobile,
        'locale': {
            'format': 'DD/MM/YYYY',
            'separator': ' - ',
            'applyLabel': 'Selecionar',
            'cancelLabel': 'Limpar',
            'daysOfWeek': ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            'monthNames': labelMeses,
            'firstDay': 1,
            'customRangeLabel': 'Selecionar Datas'
        }
    }, onSelected);

    $(id).on('cancel.daterangepicker', onClean);
};

const createDatePicker = function (id, mobile, onSelected, onClean, byText) {
    $(id).daterangepicker({
        'minDate': moment(),
        'opens': byText ? 'left' : 'right',
        'singleDatePicker': true,
        'parentEl': (mobile ? '#advancedSearchScreen .modal-body' : 'body'),
        'autoApply': mobile,
        'locale': {
            'format': 'DD/MM/YYYY',
            'separator': ' - ',
            'applyLabel': 'Selecionar',
            'cancelLabel': 'Limpar',
            'daysOfWeek': ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            'monthNames': labelMeses,
            'firstDay': 1,
            'customRangeLabel': 'Selecionar Datas'
        }
    }, onSelected);

    $(id).on('cancel.daterangepicker', onClean);
};

const createSelect2MesAno = function (id, onSelected) {
    $(id).select2({
        'minimumResultsForSearch': 30,
        'dropdownParent': $(id).parent(),
        'width': '200px'
    }).on('select2:select', onSelected);

    $(id).next().css('height', 0);
    $(id).next().css('display', 'block');
    $(id).next().children().css('display', 'none');
};

const createSelect2Destino = function (id, onSelected, width = '250px') {
    $(id).select2({
        'minimumResultsForSearch': 6,
        'dropdownParent': $(id).parent(),
        'width': width
    }).on('select2:select', onSelected);

    $(id).next().css('height', 0);
    $(id).next().css('display', 'block');
    $(id).next().children().css('display', 'none');
};

var bkpClassContainer = null;

export default {
    name: 'AdvancedSearchHome',
    props: {
        byText: {
            type: Boolean,
            default: true,
            required: true
        },
        byDate: {
            type: Boolean,
            default: false,
            required: true
        },
        byMesAno: {
            type: Boolean,
            default: false,
            required: true
        },
        byDateRange: {
            type: Boolean,
            default: false,
            required: true
        },
        byDestino: {
            type: Boolean,
            default: false,
            required: true
        },
        destinos: {
            type: Array,
            default: () => [],
            required: false
        },
        meses: {
            type: Array,
            default: () => [],
            required: false
        },
        labelProduto: {
            type: String,
            default: () => 'Passeios',
            required: false
        },
        corPrimaria: {
            type: String,
            default: () => '#000000',
            required: true
        }
    },
    data: () => ({
        insertTextMode: false,
        textInput: '',
        mesAno: '',
        destino: '',
        geolocationAvailable: true,
        lat: null,
        lng: null,
        range: '',
        data: null,
        de: null,
        ate: null,
        listaDestinos: [],
        mobileMenuVisible: false,
    }),
    computed: {
        formatedData () {
            if (!this.data) {
                return 'Selecione';
            }
            return this.data.format('DD/MM/YYYY');
        },
        formatedDateRange () {
            if (!this.de || !this.ate) {
                return 'Selecione';
            }
            return this.de.format('DD/MM/YYYY') + ' - ' + this.ate.format('DD/MM/YYYY');
        },
        formatedMesAno () {
            if (!this.mesAno) {
                return 'Selecione';
            }
            const opts = document.getElementById('advanced_search_mesAno_field').options;
            for (let i = 0; i < opts.length; i++) {
                const opt = opts[i];
                if (opt.value === this.mesAno) {
                    return opt.innerText;
                }
            }
            return '';
        },
        formatedDestino () {
            if (this.range !== '') {
                return 'até ' + this.range + 'km de você';
            }

            if (!this.destino) {
                return 'Selecione';
            }
            const opts = document.getElementById('advanced_search_destino_field').options;
            for (let i = 0; i < opts.length; i++) {
                const opt = opts[i];
                if (opt.value === this.destino) {
                    const fullDestinoLabel = opt.innerText;
                    const destinoLabel = fullDestinoLabel.split(',')[0].trim();
                    if(destinoLabel.length > 13) {
                        return destinoLabel.substr(0, 13) + '...';
                    }
                    return destinoLabel;
                }
            }
            return '';
        }
    },
    watch: {
        insertTextMode: function (novo) {
            if (novo) {
                Vue.nextTick(function () {
                    document.getElementById('field_nome_passeio').focus();
                });
            }
        },
        mesAno (novo, antigo) {
            if (novo !== antigo) {
                $('#advanced_search_mesAno_field').val(novo);
            }
        },
        destino (novo, antigo) {
            if (novo !== antigo) {
                if (novo !== '') {
                    this.range = '';
                }
                $('#advanced_search_destino_field').val(novo);
            }
        },
        range (novo, antigo) {
            if (novo !== antigo) {
                if (novo !== '') {
                    this.destino = '';
                    this.getGeolocationUser();
                }
                $('#advanced_search_geolocation_field').val(novo);
            }
        }
    },
    mounted () {
        const urlParams = new URLSearchParams(window.location.search);
        const passeio = urlParams.get('passeio');
        if (passeio) {
            this.textInput = passeio;
        }

        if (this.byDateRange) {
            window.addEventListener('load', this.activateDaterangepicker);
        }
        if (this.byMesAno) {
            window.addEventListener('load', this.activateMesAnoSelect);
        }
        if (this.byDate) {
            window.addEventListener('load', this.activateDatepicker);
        }
        if (this.byDestino) {
            window.addEventListener('load', this.activateDestinoSelect);
        }
        this.activateScrollListener();
        window.toggleSearchMenuPosition = () => this.toggleSearchMenuPosition();
    },
    created() {
        if (this.byMesAno) {
            this.meses.sort(function(a,b) {
                return a.localeCompare(b);
            });
        }

        const copy = JSON.parse(JSON.stringify(this.destinos));
        copy.sort(function(a, b) {
            if(a['titulo'] != null && b['titulo'] != null){
                return a['titulo'].localeCompare(b['titulo']);
            }

            if(a['titulo'] != null && b['titulo'] == null){
                return a['titulo'].localeCompare(b['cidade']);
            }
       
            if(a['titulo'] == null && b['titulo'] != null){
                return a['cidade'].localeCompare(b['titulo']);
            }
       
            return a['cidade'].localeCompare(b['cidade']);
        });
        this.listaDestinos = copy;
    },
    methods: {
        clean () {
            this.textInput = '';
            this.mesAno = '';
            this.destino = '';
            this.range = '';

            this.cleanDateRange();
            this.cleanData();
        },
        submit () {
            const urlParams = new URLSearchParams();
            if (this.textInput) {
                urlParams.set('passeio', this.textInput);
            }

            if (this.de && this.ate) {
                urlParams.set('de', this.de.format('YYYY-MM-DD'));
                urlParams.set('ate', this.ate.format('YYYY-MM-DD'));
            }

            if (this.data) {
                urlParams.set('data', this.data.format('YYYY-MM-DD'));
            }

            if (this.mesAno !== '') {
                urlParams.set('mesAno', this.mesAno);
            }

            if (this.destino !== '' && this.range === '') {
                urlParams.set('destino', this.destino);
            } else if (this.range !== '' && this.lat !== null && this.lng !== null) {
                urlParams.set('range', this.range);
                urlParams.set('lat', this.lat);
                urlParams.set('lng', this.lng);
            }

            const queryString = urlParams.toString();
            if (!queryString) {
                window.location = window.location.pathname;
            } else {
                window.location.href = '?' + queryString;
            }
        },
        cleanData () {
            if (!this.byDate) {
                return;
            }
            $('#advanced_search_data').data('daterangepicker').setStartDate(moment());
            $('#advanced_search_data').data('daterangepicker').setEndDate(moment());
            $('#advanced_search_data_mobile').data('daterangepicker').setStartDate(moment());
            $('#advanced_search_data_mobile').data('daterangepicker').setEndDate(moment());
            this.dateSelected(null);
        },
        cleanDateRange () {
            if (!this.byDateRange) {
                return;
            }
            $('#advanced_search_range_datas').data('daterangepicker').setStartDate(moment());
            $('#advanced_search_range_datas').data('daterangepicker').setEndDate(moment());
            $('#advanced_search_range_datas_mobile').data('daterangepicker').setStartDate(moment());
            $('#advanced_search_range_datas_mobile').data('daterangepicker').setEndDate(moment());
            this.dateRangeSelected(null, null);
        },
        dateRangeSelected (inicio, fim) {
            if (!!inicio && !!fim) {
                this.de = inicio;
                this.ate = fim;
            } else {
                this.de = null;
                this.ate = null;
            }
        },
        dateSelected (data) {
            if (data) {
                this.data = data;
            } else {
                this.data = null;
            }
        },
        activateDaterangepicker () {
            createDateRangePicker('#advanced_search_range_datas', false, this.dateRangeSelected, this.cleanDateRange, this.byText);
            createDateRangePicker('#advanced_search_range_datas_mobile', true, this.dateRangeSelected, this.cleanDateRange, this.byText);

            const urlParams = new URLSearchParams(window.location.search);
            let de = urlParams.get('de');
            let ate = urlParams.get('ate');
            if (de && ate) {
                de = moment(de, 'YYYY-MM-DD');
                ate = moment(ate, 'YYYY-MM-DD');
                if (!de.isValid() || !ate.isValid()) {
                    de = moment();
                    ate = moment();
                }
                $('#advanced_search_range_datas').data('daterangepicker').setStartDate(de);
                $('#advanced_search_range_datas').data('daterangepicker').setEndDate(ate);
                $('#advanced_search_range_datas_mobile').data('daterangepicker').setStartDate(de);
                $('#advanced_search_range_datas_mobile').data('daterangepicker').setEndDate(ate);
                this.dateRangeSelected(de, ate);
            }
        },
        activateDatepicker () {
            createDatePicker('#advanced_search_data', false, this.dateSelected, this.cleanData, this.byText);
            createDatePicker('#advanced_search_data_mobile', true, this.dateSelected, this.cleanData, this.byText);

            const urlParams = new URLSearchParams(window.location.search);
            let data = urlParams.get('data');
            if (data) {
                data = moment(data, 'YYYY-MM-DD');
                if (!data.isValid()) {
                    data = moment();
                }
                $('#advanced_search_data').data('daterangepicker').setStartDate(data);
                $('#advanced_search_data').data('daterangepicker').setEndDate(data);
                $('#advanced_search_data_mobile').data('daterangepicker').setStartDate(data);
                $('#advanced_search_data_mobile').data('daterangepicker').setEndDate(data);
                this.dateSelected(data);
            }
        },
        activateMesAnoSelect () {
            const mesAnoSelected = (e) => {
                this.mesAno = e.params.data.id;
            };
            createSelect2MesAno('#advanced_search_mesAno_field', mesAnoSelected);

            const urlParams = new URLSearchParams(window.location.search);
            const mesAno = urlParams.get('mesAno');
            if (mesAno) {
                const opts = document.getElementById('advanced_search_mesAno_field').options;
                for (let i = 0; i < opts.length; i++) {
                    const opt = opts[i];
                    if (opt.value === mesAno) {
                        this.mesAno = mesAno;
                        break;
                    }
                }
            }
        },
        activateDestinoSelect () {
            const destinoSelected = (e) => {
                this.destino = e.params.data.id;
            };
            const locRangeSelected = (e) => {
                this.range = e.params.data.id;
            };
            createSelect2Destino('#advanced_search_destino_field', destinoSelected);
            createSelect2Destino('#advanced_search_geolocation_field', locRangeSelected, '150px');

            const urlParams = new URLSearchParams(window.location.search);
            const destino = urlParams.get('destino');
            if (destino) {
                const opts = document.getElementById('advanced_search_destino_field').options;
                for (let i = 0; i < opts.length; i++) {
                    const opt = opts[i];
                    if (opt.value === destino) {
                        this.destino = destino;
                        break;
                    }
                }
            }

            const range = urlParams.get('range');
            const lat = urlParams.get('lat');
            const lng = urlParams.get('lng');
            if (range && lat && lng) {
                const opts = document.getElementById('advanced_search_geolocation_field').options;
                for (let i = 0; i < opts.length; i++) {
                    const opt = opts[i];
                    if (opt.value === range) {
                        this.range = range;
                        this.lat = lat;
                        this.lng = lng;
                        break;
                    }
                }
            }
        },
        getGeolocationUser () {
            if (this.lat !== null) {
                return true;
            }

            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.lat = position.coords.latitude;
                    this.lng = position.coords.longitude;
                },
                () => { // Error callback
                    this.range = '';
                    this.geolocationAvailable = false;
                    alert('Falha ao obter sua localização');
                }
                );
                return true;
            } else {
                this.range = '';
                this.geolocationAvailable = false;
                return false;
            }
        },
        activateScrollListener () {
            const checkScroll = () => {
                if (!document.getElementById('advanced-search-btn-topo')) {
                    return;
                }
                if (window.scrollY > 200) {
                    document.getElementById('advanced-search-btn-topo').style.display = 'inline-block';
                } else {
                    document.getElementById('advanced-search-btn-topo').style.display = 'none';
                }
            };
            document.addEventListener('scroll', checkScroll);
            checkScroll();
        },
        openSelectMesAno () {
            $('#advanced_search_mesAno_field').select2('open');
        },
        openSelectDestino () {
            $('#advanced_search_destino_field').select2('open');
        },
        openSelectGeoloc () {
            $('#advanced_search_geolocation_field').select2('open');
        },
        formatMesAno (mesAno) {
            let mes = mesAno.split('-')[1];
            let ano = mesAno.split('-')[0];
            mes = labelMeses[Number(mes) - 1];
            return mes + '/' + ano;
        },
        showSearchScreen() {
            this.mobileMenuVisible = true;
        },
        hideSearchScreen() {
            this.mobileMenuVisible = false;
        },
        toggleSearchMenuPosition() {
            const body = document.getElementsByTagName('body')[0];
            const bg = document.getElementById('searchPlaceholderBackground');
            const search = document.getElementById('paytour_advanced_search_bar_container');
            const headContainer = document.getElementById('searchPlaceholderContainer');
            const bodyContainer = document.getElementById('paytour_advanced_search_bar');

            if(!bkpClassContainer) {
                bg.style.display = 'block';
                headContainer.style.display = 'block';
                bkpClassContainer = search.className;
                search.className = '';
                headContainer.appendChild(search);
                body.style.overflow = 'hidden';
            } else {
                bg.style.display = 'none';
                headContainer.style.display = 'none';
                search.className = bkpClassContainer;
                bkpClassContainer = null;
                bodyContainer.appendChild(search);
                body.style.overflow = 'auto';
            }
        }
    }
};
</script>

<style scoped>
.advanced-search-bar-container {
   height: 64px;
   box-shadow: 0px 3px 26px #00000045;
   border-radius: 32px;
   background-color: white;
   padding-top: 10px;
   padding-bottom: 10px;
   padding-left: 20px;
   padding-right: 10px;
   display: none;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}

.advanced-search-bar-mobile-search-btn {
   width: 100%;
   text-align: center;
   height: 60px;
   background-color: white;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   padding-left: 20px;
   padding-right: 20px;
   border-radius: 30px;
   box-shadow: 0px 3px 26px #00000045;
}

.advanced-search-mobile-daterange-input {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   border: 1px solid #cccccc;
   padding: 10px;
   border-radius: 4px;
   font-size: 16px;
   cursor: pointer;
}

.advanced-search-bar-subcontainer-textsearch {
   max-width: 100%;
   cursor: text;
   flex-grow: 1;
}

.advanced-search-bar-subcontainer {
   max-width: 280px;
   cursor: text;
   flex-grow: 1;
   padding-left: 20px;
   cursor: pointer;
}

.advanced-search-bar-container .advanced-search-bar-subcontainer:not(:first-child) {
   border-left: 1px solid #E6E6E6;
}

.advanced-search-bar-title {
   font-weight: 500;
   font-size: 14px;
}

.advanced-search-bar-subtitle {
   font-weight: 300;
   color: black;
}

.advanced-search-bar-textinput {
   border: none;
   outline: none;
   font-size: 25px;
   background-color: white;
   color: #4A545A;
   max-width: 80%;
}

.positioned-under-casousel {
  z-index: 10;
  position: relative;
  top: -34px;
}
.positioned-under-casousel-desktop {
    position: relative;
    top: -34px;
}
.positioned-under-header {
  margin-top: 20px;
  margin-bottom: 20px;
}

.search-mobile-container {
  position: fixed;
  overflow-y: scroll;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 1000;
  width: 100%;
  min-height: 100vh;
  background-color: white;
  padding: 20px;
}

@media (min-width: 768px) {
   .advanced-search-bar-container {
      display: flex;
   }

   .advanced-search-bar-mobile-search-btn {
      display: none;
   }
}
@media (max-width: 768px) {
  .positioned-under-casousel {
    top: -100px;
  }
  .positioned-under-casousel-desktop {
    position: static;
    top: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>
